import React, { useState, useEffect } from 'react';
import { Typography, Box, Avatar } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { setProfileData } from '../../utils/redux/apiCacheReducer/actions';
import { fetchProfileData } from '../../thunks'

function Helmet() {
  const [companyName, setCompanyName] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');
  const { accessToken } = useAuth();
  const dispatch = useDispatch();
  const cachedProfileData = useSelector((state) => state.apiCacheReducer.profileData);
  
  useEffect(() => {
    let nickname, avatar, availableBalance; 

    if (Object.values(cachedProfileData).some(Boolean)) {
      ({ nickname, avatar, availableBalance } = cachedProfileData);
    } else {
      fetchProfileData(accessToken).then(data => {
        ({ nickname, avatar, availableBalance } = data);
        dispatch(setProfileData({ nickname, avatar, availableBalance }));
      })
    }

    setCompanyName(nickname || '');
    setAvatarUrl(avatar || '');
  }, [accessToken, cachedProfileData, dispatch])

  return (
      <Box sx={{ 
        width: '100%', 
        height: '10vh', 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}>
          <Avatar
            sx={{
              width: '3.3vw',
              height: '3.3vw',
              backgroundColor: '#5D31B8',
              fontSize: '1.24vw',
              marginLeft: 'auto'
            }}
            variant="circular"
            alt={companyName}
            src={`${process.env.REACT_APP_API_BASE_URL}${avatarUrl}`}
          />

          <Box sx={{marginLeft: '1vw'}}>
            <Typography fontSize={'0.83vw'}>{companyName}</Typography>
          </Box>
      </Box>
  );
}

export default Helmet;