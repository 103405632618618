import ApiManager from '../utils/apiManager'

export default class UtilsService {
  constructor() {
    this.apiManager = new ApiManager();
  }

  async getAddressByCoords(accessToken, params) {
    try {
      const response = await this.apiManager.get({
        url: '/api/',
        config: {
          params,
          headers: {
            Authorization: `token ${accessToken}`
          },
        },
      });
    
      return response.data;
    } catch (error) {
      console.log(error)
    }
  }

}