import React, {useEffect, useRef} from 'react';
import {
  Box, Grid, Typography,
  FormControlLabel,
  RadioGroup, Radio,
  TextField,
  FormHelperText,
  InputAdornment
} from '@mui/material';
import InputMask from 'react-input-mask';
import {Controller, useFormContext} from 'react-hook-form';
import ParkingPlace from './ParkingPlace';
import { defaultRequired } from './../../rules';
import { styleFormControlLabel, styleInput } from './styles';
import { useYMaps } from '@pbe/react-yandex-maps';
import { 
  ParkingPlaceOperatorIcon,
  BusinessCenterIcon, 
  HoReCaIcon, 
  MallIcon, 
  RadioCheckedIcon, 
  RadioIcon,
  AddressAdornmentIcon
 } from '../../SVGs';
 import UtilsService from '../../../../services/utils.service';
 import useAuth from '../../../../hooks/useAuth';

const BasicInfo = () => {
  const {control, ...methods} = useFormContext();
  const mapRef = useRef(null);
  const ymaps = useYMaps(['Map', 'GeoObject']);
  const {accessToken} = useAuth();

  useEffect(() => {
    const element = mapRef.current;
    if (!ymaps || !element) {
      return;
    }

    if(document.getElementsByTagName('ymaps').length) {
      return;
    }

    let map = new ymaps.Map(element, {
      center: [55.76, 37.64], 
      zoom: 10
    });

    map.cursors.push('pointer');
    map.events.add('click', (e) => {
      map.geoObjects.removeAll();
      const coords = e.get('coords');
      methods.setValue('coords', coords);
  
      let point = new ymaps.GeoObject({
        geometry: {
          type: "Point",
          coordinates: coords
        }
      }, { iconLayout: 'default#image', iconImageHref: 'placemark.svg', iconImageSize: [30, 37], }); // TODO iconImageHref не видит папку public/
      map.geoObjects.add(point);
      
      const fetchAddress = async () => {
        const utilsService = new UtilsService();
        const response = await utilsService.getAddressByCoords(accessToken, {
          latitude: coords[0],
          longitude: coords[1],
        })
        return response;
      }
      
      fetchAddress().then(raw => {
        console.log(raw);

      })

    });
  }, [accessToken, methods, ymaps]);

  const paramsRadioButton = {
    sx: styleFormControlLabel,
    labelPlacement: "start",
    control: <Radio 
      icon={<RadioIcon />} 
      checkedIcon={<RadioCheckedIcon />}
      sx={{
        marginTop: '2vh',
        marginRight: '2vh',
        padding: 0
      }} 
    />
  }

  return <>
    <div>
      <Box sx={{marginTop: '10vh'}}>
        <Typography sx={{ fontSize:' 1.24vw', color: '#5D31B8', marginBottom: '2vh' }}>Добавить парковочное место в PARKIFY и готовьтесь принимать гостей.</Typography>
        <Grid container>
          <Controller
            rules={{ required: defaultRequired.parkingType.message }}
            render={({ field, fieldState:{error} }) => (<Box sx={{ width: '100%' }}>
              <RadioGroup aria-label="gender" {...field} row sx={{
                flexWrap: 'nowrap',
                width: '100%',
                marginTop: 2,
                marginBottom: 2
              }}>
                <FormControlLabel {...paramsRadioButton} value="parkingPlaceOperator" label={<ParkingPlace icon={<ParkingPlaceOperatorIcon />} title="Оператор парковочных мест"/>} />
                <FormControlLabel {...paramsRadioButton} value="businessCenter" label={<ParkingPlace icon={<BusinessCenterIcon />} title="Бизнес центр"/>} />
                <FormControlLabel {...paramsRadioButton} value="HoReCa" label={<ParkingPlace icon={<HoReCaIcon />} title="HoReCa"/>} />
                <FormControlLabel {...paramsRadioButton} value="shoppingCenter" label={<ParkingPlace icon={<MallIcon />} title="Торговый центр"/>} />
              </RadioGroup>
                {error?.message && <FormHelperText error>{error.message}</FormHelperText>}
              </Box>
            )}
            name="parkingType"
            control={control}
          />
        </Grid>
      </Box>
      
      <Box sx={{marginTop:'5vh', marginBottom:'3vh' }}>
        <Typography sx={{ fontSize:'1.24vw', color: '#5D31B8', marginBottom: '2vh' }}>Укажите расположение вашего парковочного места на карте</Typography>
        <Grid container>
          <Grid item xs={12}>
            <Controller 
              name="coords"
              control={control}
              defaultValue={[]}
              rules={{ required: defaultRequired.coords.message }}
              render={({ fieldState: { error } }) => <><Box
                id={'map'} 
                width={'inherit'}
                height={'50vh'}
                ref={mapRef}
                sx={{
                    borderRadius: '16px',
                    marginBottom: '1vh',
                    overflow: 'hidden',
                    border: error && !methods.getValues().coords.length ? '1px solid red' : 'none',
                    '[class*="ymaps-2"][class*="-ground-pane"]': {
                      filter: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale")'
                    }
                }}
              />
              {error?.message && !methods.getValues().coords.length && <FormHelperText error>{error.message}</FormHelperText>}
              </>}
            />
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3} sx={{ marginTop: '3vh', marginBottom: '5vh' }}>
        <Grid item xs={8}>
          <Box>
            <Controller
              name="parkingAddress"
              control={control}
              rules={{ required: defaultRequired.parkingAddress.required }}
              render={({ field, fieldState: { error } }) => <><TextField 
                // disabled // TODO make it disable soon
                label="Адрес"
                defaultValue={''}
                error={!!error?.message}
                sx={styleInput}
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="start"><AddressAdornmentIcon /></InputAdornment>,
                }}
                {...field} 
              />
              {/* {error?.message && <FormHelperText error>{error.message}</FormHelperText>} */}
              </>}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box>
            <Controller
              name="parkingPhone"
              control={control}
              rules={{ required: defaultRequired.parkingPhone.message }}
              render={({ field: { onChange, value }, fieldState: { error }  }) => (
                <>
                <InputMask mask="+9 (999) 999 9999" value={value} onChange={onChange}>
                  {
                    inputProps => (
                      <TextField
                        error={!!error?.message}
                        label="Номер телефона"
                        variant="outlined"
                        sx={styleInput}
                        type="text"
                        fullWidth
                        {...inputProps}
                      />
                    )
                  }
                </InputMask>
                {error?.message && <FormHelperText error>{error.message}</FormHelperText>}
                </>
              )}
            />
          </Box>
        </Grid>
      </Grid>

      
    </div>
  </>
}

export default BasicInfo;